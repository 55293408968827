import { Link } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import { Container, Row, Column } from "../components/System/Grids";
const environment = {
  lang: "en",
  path: "/404",
  altHrefLang: [
    {
      lang: "en",
      path: "/404",
    },
  ],

  theme: "orange",
  seo: {
    title: "Page not found",
    description: "The page you are looking for doesn't exist",
  },
};
const NotFoundPage = () => (
  <Layout environment={environment}>
    <Container>
      <Row>
        <Column>
          <br />
          <br />
          <br />
          <h1>We can't find the page you were looking for</h1>
          <p>
            You can navigate back to our{" "}
            <strong>
              <Link to="/en">homepage</Link>
            </strong>{" "}
            and start over.
          </p>
          <p>
            Otherwise, please contact us in{" "}
            <a href="mailto:info@thedutchonlineacademy.com">
              info@thedutchonlineacademy.com
            </a>{" "}
            for more info!
          </p>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
